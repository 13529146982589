









































import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: ['inputValue', 'required', 'disabled', 'rules'],
  setup(props, ctx) {
    const dateOpen = ref(false);

    return {
      dateOpen,
      date: computed(() => {
        if (props.inputValue) {
          return new Date(props.inputValue).toISOString().substr(0, 10);
        }
        return null;
      }),
      dateInput: computed(() => {
        if (props.inputValue) {
          const [year, month, day] = new Date(props.inputValue)
            .toISOString()
            .substr(0, 10)
            .split('-');
          return `${month}-${day}-${year}`;
        }
        return null;
      }),
      cancel: event => {
        ctx.emit('clear', event);
        dateOpen.value = false;
      },
    };
  },
});
