import { project } from '@/+state';
import { Demographics } from '@conversa/bedazzled/src/models/demographics.interface';

export const loadingCapabilities = project<boolean>(
  ({ stores }) => stores.capabilitiesStore.loading,
);

export const demographicCapabilities = project<Demographics>(
  ({ stores }) => stores.capabilitiesStore.capabilities.demographics,
);

export const capabilitiesProjections = [
  loadingCapabilities,
  demographicCapabilities,
];
