import { render, staticRenderFns } from "./EnrollmentDetailsDialogContainer.vue?vue&type=template&id=30f503e2&scoped=true&"
import script from "./EnrollmentDetailsDialogContainer.vue?vue&type=script&lang=ts&"
export * from "./EnrollmentDetailsDialogContainer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30f503e2",
  null
  
)

export default component.exports