





























import BlueCard from '@/shared/components/BlueCard.vue';
import { ExpandableCard } from '@conversa/bedazzled/src/cards';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: ['survey'],
  components: {
    BlueCard,
    ExpandableCard,
  },
  setup(_, ctx) {
    const isOpen = ref(false);

    const toggleOpen = () => (isOpen.value = !isOpen.value);

    const handleEnroll = surveyId => ctx.emit('handle-enroll', surveyId);

    return {
      isOpen,
      toggleOpen,
      handleEnroll,
    };
  },
});
