import { project } from '@/+state';
import {
  monthDayYearLeadingZeros12hTime,
  monthFullNameDayYearNoLeadingZeros,
} from '@/constants/dateTime';
import {
  CheckupEnhanced,
  CheckupHeader,
} from '@/models/checkups/checkup.interface';
import { noDataIndicator } from '@/shared/content';
import { TableOptionsFrontend } from '@/shared/models/table-options';
import { formatISODate } from '@/shared/utils';

const transformCheckups = checkups => {
  return checkups.map(checkup => {
    return {
      ...checkup,
      formattedDate: formatISODate(
        checkup.checkupUpdatedAt,
        monthDayYearLeadingZeros12hTime,
      ),
    };
  });
};

export const checkupsAll = project<CheckupEnhanced[]>(({ stores }) => {
  return transformCheckups(stores.checkupsStore.data);
});

export const checkupsMostRecent = project<CheckupEnhanced[]>(({ stores }) => {
  return transformCheckups(stores.checkupsStore.initialData).slice(0, 3);
});

export const checkupHeader = project<CheckupHeader>(({ stores }) => {
  const checkup = stores.checkupsStore.data.sort(
    (a, b) =>
      new Date(b.checkupUpdatedAt).getTime() -
      new Date(a.checkupUpdatedAt).getTime(),
  )[0];

  return {
    date: checkup
      ? formatISODate(
          checkup.checkupUpdatedAt,
          monthFullNameDayYearNoLeadingZeros,
        )
      : noDataIndicator,
    surveyName: checkup?.surveyName || null,
    status: checkup?.status || null,
  };
});

export const checkupsLoading = project<boolean>(
  ({ stores }) => stores.checkupsStore.loading,
);

export const checkupsCount = project<number>(
  ({ stores }) => stores.checkupsStore.count,
);

export const checkupsRenderComponent = project<boolean>(
  ({ stores }) => stores.checkupsStore.renderComponent,
);

export const checkupsTableOptions = project<TableOptionsFrontend>(
  ({ stores }) => stores.checkupsStore.tableOptions,
);

export const checkupsProjections = [
  checkupsAll,
  checkupHeader,
  checkupsMostRecent,
  checkupsLoading,
  checkupsCount,
  checkupsRenderComponent,
  checkupsTableOptions,
];
