import subYears from 'date-fns/subYears';

import { isDateValid } from './utils';

export const dateFormatRule = (date: string) =>
  new RegExp('^([0-9]{2}-[0-9]{2}-[0-9]{4})?$').test(date) ||
  'Date must be in the format of mm-dd-yyyy';

export const dateValidRule = ({
  date,
  dateFormat,
}: {
  date: string;
  dateFormat: string;
}) => isDateValid({ date, dateFormat }) || 'Please enter a valid date';

export const dateRangeRule = ({
  date,
  numYears = 0,
}: {
  date: string;
  numYears: number;
}) => {
  let d1 = date.slice();

  // NOTE: (mk) This is to capture and adjust for an
  //            issue with FF and date parsing that happens.
  if (new Date(d1).toString() === 'Invalid Date') d1 = date.replace(/-/g, '/');

  return (
    (new Date(d1) <= new Date() &&
      new Date(d1) > subYears(new Date(), numYears)) ||
    `Please enter a date within the last ${numYears} years`
  );
};
