








import { computed, defineComponent, ref } from '@vue/composition-api';
import { tap } from 'rxjs/operators';
import { Component } from 'vue';
import { DialogRef, DialogEvent } from './actions';
import DialogContainer from './DialogContainer.vue';

export default defineComponent({
  components: {
    DialogContainer,
  },
  setup() {
    const componentRef = ref<Component>();
    const componentRefOpts = ref<{
      [k: string]: unknown;
    }>({});
    const dialogRef = DialogRef();

    dialogRef.events$
      .pipe(
        tap((dialog: DialogEvent) => {
          componentRef.value = dialog.component;
          componentRefOpts.value = dialog.opts;
        }),
      )
      .subscribe();

    return {
      dialog: computed(() => componentRef.value),
      dialogOpts: computed(() => componentRefOpts.value),
      onClose: () => {
        if (componentRefOpts.value && componentRefOpts.value.closable === false)
          return;

        dialogRef.close();
      },
    };
  },
});
