<template>
  <header :class="{ isNotHome: !isHome }" v-if="loading === false">
    <div class="header__top">
      <span class="details" v-if="isHome">
        <div>
          <h3>{{ patientHeader.patientName }}</h3>
          <p>Created on {{ patientHeader.createdAt }}</p>
        </div>
      </span>
      <v-btn top class="back-btn" to="/" v-else>
        <v-icon left>chevron_left</v-icon>Summary
      </v-btn>
      <div>
        <StatusPill v-if="isHome" />
        <ActionMenu />
      </div>
    </div>
    <div class="header__top" v-if="isHome">
      <div class="last-chat" v-if="checkupHeader.surveyName">
        <div v-if="checkupHeader.status">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <StatusIndicatorIcon :status="checkupHeader.status" />
              </div>
            </template>
            <span>Color Status: {{ checkupHeader.status }}</span>
          </v-tooltip>
        </div>
        <div class="bold">Last chat</div>
        <div>{{ checkupHeader.date }}</div>
        <div>{{ checkupHeader.surveyName }}</div>
      </div>
    </div>
    <v-tabs v-if="!isHome" slider-size="3" grow show-arrows>
      <v-tab :ripple="false" to="/checkups">Completed Chats</v-tab>
      <v-tab :ripple="false" to="/schedule">Scheduled Chats</v-tab>
      <v-tab :ripple="false" to="/activity">Activity</v-tab>
    </v-tabs>
  </header>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import StatusIndicatorIcon from '@/shared/components/StatusIndicatorIcon.vue';
import ActionMenu from './ActionMenu.vue';
import StatusPill from './StatusPill.vue';
import { select } from '@conversa/reflex';
import { patientDetailHeader } from '@/+state/patient-details/projections';
import { checkupHeader } from '@/+state/checkups/projections';
import { isMiscLoading } from '@/+state/misc/projections';

export default defineComponent({
  components: {
    ActionMenu,
    StatusPill,
    StatusIndicatorIcon,
  },
  setup(_, ctx) {
    return {
      isHome: computed(() => ctx.root.$route?.fullPath?.includes('/summary')),
      patientHeader: select(patientDetailHeader),
      checkupHeader: select(checkupHeader),
      loading: select(isMiscLoading),
    };
  },
});
</script>

<style lang="scss" scoped>
header {
  border-bottom: rem(1) solid #b3b3b3;
  display: block;
  padding: rem(16) rem(24) rem(0) rem(24);

  &.isNotHome {
    margin-bottom: rem(16);
    padding-bottom: 0;

    div.header__top {
      align-items: center;
    }
  }
}

.v-tab {
  letter-spacing: normal;
}

div.header__top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  .back-btn {
    font-size: rem(18);
    text-decoration: none;
    letter-spacing: normal;
  }

  .details {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  .bold {
    font-weight: 700;
  }

  .last-chat {
    display: flex;
    justify-self: flex-start;
  }

  .last-chat > :first-child {
    padding-top: rem(6);
  }

  .last-chat > :not(:first-child) {
    padding-left: rem(10);
    padding-right: rem(10);
  }

  p {
    display: flex;
    justify-self: flex-start;
    margin: 0;
  }

  .status-pill {
    margin-right: rem(16);
  }

  > div {
    display: flex;
    align-items: center;
  }
}

h1 {
  font-size: rem(20);
  margin: 0;
  padding: 0;
}
</style>
