import { project } from '@/+state';
import { monthDayYearLeadingZeros12hTime } from '@/constants/dateTime';
import { Activity } from '@/models/activity/activity.interface';
import { TableOptionsFrontend } from '@/shared/models/table-options';
import { formatISODate } from '@/shared/utils';
import { select } from '@conversa/reflex';

const transformActivities = activities => {
  return activities.map(activity => {
    return {
      ...activity,
      formattedDate: formatISODate(
        activity.date,
        monthDayYearLeadingZeros12hTime,
      ),
    };
  });
};

export const activityActivities = project<Activity[]>(({ stores }) => {
  return transformActivities(stores.activityStore.data);
});

export const activitySummary = project<Activity[]>(({ stores }) => {
  return transformActivities(stores.activityStore.initialData).slice(0, 3);
});

export const activityActivitiesLength = project<number>(
  () => select(activityActivities).value.length,
);

export const activityCount = project<number>(({ stores }) => {
  return stores.activityStore.count;
});

export const activityLoading = project<boolean>(
  ({ stores }) => stores.activityStore.loading,
);

export const activityTableOptions = project<TableOptionsFrontend>(
  ({ stores }) => stores.activityStore.tableOptions,
);

export const activityProjections = [
  activityActivities,
  activitySummary,
  activityActivitiesLength,
  activityCount,
  activityLoading,
  activityTableOptions,
];
