




























import {
  monthDayYearLeadingZerosHyphenated,
  yearMonthDayLeadingZeros,
} from '@/constants/dateTime';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { format } from 'date-fns';
import { formatISODate, isDateValid } from '../utils';

export default defineComponent({
  emits: ['date-updated'],
  props: {
    isoDate: {
      required: true,
      type: String,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    errorMessage: {
      default: '',
      type: String,
    },
  },
  setup(props, ctx) {
    const readableDate = ref(
      formatISODate(props.isoDate, monthDayYearLeadingZerosHyphenated),
    );

    const emit = val => {
      ctx.emit('date-updated', val);
    };

    watch(
      () => props.isoDate,
      newVal => {
        readableDate.value = formatISODate(
          newVal,
          monthDayYearLeadingZerosHyphenated,
        );
      },
    );

    return {
      readableDate,
      onTextChange: newDate => {
        if (
          !isDateValid({
            date: newDate,
            dateFormat: monthDayYearLeadingZerosHyphenated,
          })
        ) {
          return;
        }

        emit(
          format(
            new Date(newDate.replace(/-/g, '/')),
            yearMonthDayLeadingZeros,
          ),
        );
      },
      onDateChange: val => {
        emit(val);
      },
    };
  },
});
