














import { EnrollmentSubmitted } from '@/+state/enrollments/events';
import { selectedProgram } from '@/+state/enrollments/projections';
import { DialogRef, DIALOG_PROPS } from '@conversa/bedazzled/src/dialog';
import { emit, select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';
import EnrollmentDialogContainer from './EnrollmentDetailsDialogContainer.vue';
import EnrollmentProgramListVue from './EnrollmentProgramList.vue';

export default defineComponent({
  components: {
    EnrollmentDialogContainer,
  },
  props: [...DIALOG_PROPS],
  setup() {
    const dialog = DialogRef();

    return {
      program: select(selectedProgram),
      back: () => {
        dialog.open(EnrollmentProgramListVue, {
          fullscreen: true,
          closable: false,
        });
      },
      enroll(payload) {
        emit(EnrollmentSubmitted(payload));
      },
    };
  },
});
