














import { EnrollmentUpdateStatusClicked } from '@/+state/enrollments/events';
import {
  selectedEnrolledProgram,
  selectedEnrollment,
} from '@/+state/enrollments/projections';
import { EnrollmentStatusEnum } from '@/shared/models';
import { DialogInstance, DIALOG_PROPS } from '@conversa/bedazzled/src/dialog';
import { emit, select } from '@conversa/reflex';
import { defineComponent } from '@vue/composition-api';
import EnrollmentDialogContainer from './EnrollmentDetailsDialogContainer.vue';

export default defineComponent({
  components: {
    EnrollmentDialogContainer,
  },
  props: [...DIALOG_PROPS],
  setup() {
    const enrollment = select(selectedEnrollment).value;

    return {
      program: select(selectedEnrolledProgram),
      back: () => {
        DialogInstance.close();
      },
      enroll(payload) {
        emit(
          EnrollmentUpdateStatusClicked({
            authoringId: enrollment.surveyAuthoringId,
            newApprovalStatus: EnrollmentStatusEnum.Approved,
            enrollmentAnswers: payload.enrollmentQuestionAnswers,
          }),
        );
      },
    };
  },
});
