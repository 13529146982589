













import { EnrollmentUpdateSubmitted } from '@/+state/enrollments/events';
import { selectedEnrolledProgram } from '@/+state/enrollments/projections';
import { DialogUnmounted } from '@/+state/misc/events';
import { DialogRef, DIALOG_PROPS } from '@conversa/bedazzled/src/dialog';
import { emit, select } from '@conversa/reflex';
import { defineComponent, onUnmounted } from '@vue/composition-api';
import EnrollmentDialogContainer from './EnrollmentDetailsDialogContainer.vue';

export default defineComponent({
  components: {
    EnrollmentDialogContainer,
  },
  props: [...DIALOG_PROPS],
  setup() {
    const dialog = DialogRef();

    onUnmounted(() => {
      emit(DialogUnmounted({ dialogName: 'EnrollmentUpdateDialog' }));
    });

    return {
      program: select(selectedEnrolledProgram),
      back: () => {
        dialog.close();
      },
      enroll(payload) {
        emit(EnrollmentUpdateSubmitted(payload));
      },
    };
  },
});
