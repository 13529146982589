import { handle } from '@/+state';
import AppErrorDialogVue from '@/dialogs/AppErrorDialog.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { emit } from '@conversa/reflex';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { AppMounted } from './../misc/events';
import { UserAccessTokenInvalid, UserAccessTokenSet } from './events';

export const grabTokenFromUri = handle([AppMounted], async ({ core }) => {
  const sessionStateKey = 'smrfi-access-token';
  const accessToken = new URLSearchParams(location.hash.slice(1)).get(
    'access_token',
  );
  history.pushState({}, '', '/summary');

  if (accessToken) sessionStorage.setItem(sessionStateKey, accessToken);
  const token = sessionStorage.getItem(sessionStateKey);

  if (token) {
    core.stores.userStore.accessToken = token;
    emit(UserAccessTokenSet());
  } else {
    emit(UserAccessTokenInvalid());
  }
});

export const accessTokenSet = handle([UserAccessTokenSet], ({ core }) => {
  clearTimeout(core.stores.userStore.dialogTimeoutId);

  const tokenPayload = jwtDecode<JwtPayload>(core.stores.userStore.accessToken);
  const timer = tokenPayload?.exp ? tokenPayload.exp * 1000 - Date.now() : 0;

  core.stores.userStore.dialogTimeoutId = window.setTimeout(() => {
    emit(UserAccessTokenInvalid());
  }, timer);
});

export const accessTokenInvalid = handle(
  [UserAccessTokenInvalid],
  ({ core }) => {
    clearTimeout(core.stores.userStore.dialogTimeoutId);
    DialogInstance.open(AppErrorDialogVue, {
      title: 'Session Expired',
      message:
        'Your session has timed out due to inactivity. Relaunch Conversa to start a new session.',
    });
  },
);

export const userHandlers = [
  grabTokenFromUri,
  accessTokenSet,
  accessTokenInvalid,
];
