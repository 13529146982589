import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Iconfont } from 'vuetify/types/services/icons';

Vue.use(Vuetify);

const themeColors = {
  anchor: '#46AFF2',
};

const opts = {
  theme: {
    options: {
      variations: false,
    },
    themes: {
      dark: {
        primary: '#46AFF2',
        ...themeColors,
      },
      light: {
        primary: '#000000',
        ...themeColors,
      },
    },
    dark: true,
  },
  icons: {
    iconfont: 'mdiSvg' as Iconfont,
  },
};

export default new Vuetify(opts);
