import {
  monthDayYearLeadingZeros,
  yearMonthDayLeadingZeros,
} from '@/constants/dateTime';
import {
  PatientSession,
  PatientSessionProjection,
} from '@/models/survey-sessions';
import format from 'date-fns/fp/format';
import parseISO from 'date-fns/fp/parseISO';
import { isValid, parse } from 'date-fns';
import { content } from './content';
import { PatientLanguage, PatientStatus } from './models';

export const stateCodes = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'States of Micronesia', value: 'FM' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Palau', value: 'PW' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'New Brunswick', value: 'NB' },
  { label: 'Newfoundland and Labrador', value: 'NL' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon', value: 'YT' },
];

export const isDateValid = ({
  date,
  dateFormat,
}: {
  date: string;
  dateFormat: string;
}) => isValid(parse(date, dateFormat, new Date()));

export function formatISODate(
  isoDateString: string,
  dateFormat = monthDayYearLeadingZeros,
) {
  let formatted: string;
  try {
    formatted = format(dateFormat, parseISO(isoDateString));
  } catch (error) {
    console.warn('formatISODate failed - using "N/A" as fallback', {
      isoDateString,
      format,
      error,
    });
    formatted = 'N/A';
  }

  return formatted;
}

export function isoTimestampToDateInput(isoTimestamp: string) {
  if (!isoTimestamp) {
    return null;
  }

  try {
    return format(yearMonthDayLeadingZeros, new Date(isoTimestamp));
  } catch (error) {
    console.warn('isoTimestampToDateInput could not format the timestamp', {
      isoTimestamp,
      error,
    });
    return isoTimestamp;
  }
}

export function formatPatientName(firstName: string, lastName: string): string {
  if (!firstName && !lastName) return 'Name unknown';
  return `${firstName || '(First name unknown)'} ${lastName ||
    '(Last name unknown)'}`;
}

export function humanReadableLanguage(language: PatientLanguage) {
  switch (language) {
    case 'en':
      return content.en.language.en;

    case 'es':
      return content.en.language.es;

    default:
      return language;
  }
}

export function humanReadableActiveCaregiver(hasActive: 't' | 'f') {
  switch (hasActive) {
    case 't':
      return content.en.activeCaregiver.t;

    case 'f':
      return content.en.activeCaregiver.f;

    default:
      return hasActive;
  }
}

export function humanReadablePatientStatus(status: PatientStatus) {
  switch (status) {
    case 'opted_in':
      return content.en.patientStatus.in;

    case 'opted_out':
      return content.en.patientStatus.out;

    default:
      return status;
  }
}

export function buildFilterPayload<T>(
  /* eslint-disable */
  store: { filters: { [k: string]: any } },
  paramMap: Map<string, string>,
  /* eslint-enable */
  context: string,
) {
  return Object.keys(store.filters).reduce((filters, key) => {
    const val = store.filters[key];
    if ((Array.isArray(val) && val.length) || (!Array.isArray(val) && val)) {
      const filterKey = paramMap.get(key);

      if (filterKey) {
        filters[filterKey] = val;
      } else {
        console.warn(`${context} attempted to set an unknown filter`, {
          filterKey,
        });
      }
    }

    return filters;
  }, {}) as T;
}

/* eslint-disable @typescript-eslint/camelcase */
export function mapPayload<T>(
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  srcParams: Record<string, any>,
  paramMap: Map<string, string>,
  /* eslint-enable */
  context: string,
) {
  return Object.keys(srcParams).reduce((targetParams, key) => {
    const val = srcParams[key];
    if ((Array.isArray(val) && val.length) || (!Array.isArray(val) && val)) {
      const paramKey = paramMap.get(key);

      if (paramKey) {
        targetParams[paramKey] = val;
      } else {
        console.warn(`${context} attempted to map an unknown parameter`, {
          paramKey,
        });
      }
    }

    return targetParams;
  }, {}) as T;
  /* eslint-enable @typescript-eslint/camelcase */
}

export function handleErrorMessage({ store }) {
  const errorMessage = [];

  const message = store.error?.message;

  const detailErrorsIsArray = Array.isArray(store.error?.details?.errors);
  const details =
    (detailErrorsIsArray && store.error.details.errors[0]?.message) ||
    store.error?.details;

  const errors =
    detailErrorsIsArray && store.error.details.errors[0]?.value?.toString();

  if (message) errorMessage.push(message);
  if (details) errorMessage.push(`- ${details}`);
  if (errors) errorMessage.push(`: ${errors}`);

  return errorMessage.join(' ');
}

export const formatToSurveySessionProjection = (
  session: PatientSession,
): PatientSessionProjection =>
  session && {
    date: formatISODate(session.date, monthDayYearLeadingZeros),
    unformattedDate: session.date,
    surveyNames: session.surveyNames.join(', '),
  };
