






















































import { Alert } from '@conversa/bedazzled/src/alerts';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DIALOG_PROPS,
} from '@conversa/bedazzled/src/dialog';
import { defineComponent, ref } from '@vue/composition-api';
import { ErrorState } from '@conversa/bedazzled/src/models/error-state.interface';

// required to import due to vuetify not auto importing
import { VSelect, VTextField } from 'vuetify/lib';

export default defineComponent<{
  isFormValid: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  concepts: any[];
  error: ErrorState;
  programName: string;
  title: string;
}>({
  props: [
    'dialogClassName',
    'isFormValid',
    'concepts',
    'error',
    'programName',
    'title',
    ...DIALOG_PROPS,
  ],
  emits: ['is-form-valid', 'changed', 'enroll', 'clear'],
  components: {
    Alert,
    Dialog,
    DialogContent,
    DialogActions,
    VSelect,
    VTextField,
  },
  setup(_, context) {
    const validateEnrollment = ref(null);

    return {
      validateEnrollment,
      onFormValidInput: val => context.emit('is-form-valid', val),
      onInputChanged: event => context.emit('changed', event),
      enroll: () =>
        validateEnrollment.value.validate() && context.emit('enroll'),
      onClear: event => context.emit('clear', event),
    };
  },
});
